import React, { useState } from 'react'
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'
import { Avatar, Item, Token, VStack, Widget } from '@revolut/ui-kit'

import { CommonTabProps } from '../common'
import { Month } from './Month'
import startOfMonth from 'date-fns/startOfMonth'
import subMonths from 'date-fns/subMonths'
import isThisMonth from 'date-fns/isThisMonth'
import addMonths from 'date-fns/addMonths'

export const PayCycleTabChanges: React.FC<CommonTabProps> = ({ data }) => {
  const [loadedMonths, setLoadedMonths] = useState<number[]>([])

  const cycleStartDate = data?.start_date
  const monthsFromCycleStart = cycleStartDate
    ? differenceInCalendarMonths(new Date(), new Date(cycleStartDate))
    : 1
  const firstDaysByMonths = new Array(monthsFromCycleStart)
    .fill(null)
    .map((_, i) => startOfMonth(subMonths(new Date(), i)))

  return (
    <Widget maxWidth={Token.breakpoint.md}>
      <VStack space="s-8">
        <Item>
          <Item.Avatar>
            <Avatar useIcon="16/ListBullet" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Pay group changes overview</Item.Title>
          </Item.Content>
        </Item>
        {firstDaysByMonths.map(startOfMonthDate => {
          return (
            <Month
              key={startOfMonthDate.valueOf()}
              payGroupId={data?.pay_group.id}
              startOfMonthDate={startOfMonthDate}
              allowLoading={
                // BE doesn't paginate data, so for requests optimization
                // we load months one by one, while checking if they are already in the viewport
                isThisMonth(startOfMonthDate) ||
                loadedMonths.includes(addMonths(startOfMonthDate, 1).valueOf())
              }
              onLoaded={dateString => {
                setLoadedMonths([...loadedMonths, dateString])
              }}
            />
          )
        })}
      </VStack>
    </Widget>
  )
}

import React from 'react'
import format from 'date-fns/format'
import get from 'lodash/get'
import {
  Avatar,
  chain,
  createChain,
  HStack,
  Icon,
  IconName,
  Item,
  Text,
  Token,
} from '@revolut/ui-kit'

import {
  PayrollTimelineDomainCategory,
  PayrollTimelineEventInterface,
} from '@src/interfaces/payrollV2'
import { formatDate } from '@src/utils/format'
import { formatSnakeCase } from '@src/utils/string'

const categoryToIcon = (data: PayrollTimelineEventInterface): IconName => {
  const event = data.payroll_event
  const category = data.content.domain_category

  if (event === 'hire') {
    return 'AddContact'
  }
  if (event === 'termination') {
    return 'BrokenHeart'
  }
  if (event === 'data_change') {
    const mapCategoryToIcon: Record<PayrollTimelineDomainCategory, IconName> = {
      work_details: 'Services',
      contract: 'Document',
      personal_details: 'NationalId',
      time_off: 'TimeAndMoney',
    }
    return mapCategoryToIcon[category] || 'QuestionSign'
  }
  return 'QuestionSign'
}

const getTitle = (data: PayrollTimelineEventInterface) => {
  const event = data.payroll_event
  const category = data.content.domain_category

  switch (event) {
    case 'data_change':
      return `${formatSnakeCase(category)} change`
    case 'hire':
      return 'New hire'
    case 'termination':
      return 'Termination'
    default:
      return 'Unknown event'
  }
}

type ChangePreviewValuesProps = {
  fieldPath?: string
  label?: string
  from?: React.ReactNode
  to: React.ReactNode | undefined
}
const ChangePreviewValues = ({
  fieldPath,
  label,
  from,
  to,
}: ChangePreviewValuesProps) => {
  const arrowChain = createChain(<Icon name="ArrowThinRight" size={12} />)
  const fieldLabel = label || (fieldPath ? formatSnakeCase(fieldPath) : null)

  if (!to) {
    return null
  }
  return (
    <>
      <Item.Value>
        <HStack align="center">
          {arrowChain(
            from ? (
              <Text color={Token.color.greyTone50} textDecoration="line-through">
                {from}
              </Text>
            ) : undefined,
            <Text>{to}</Text>,
          )}
        </HStack>
      </Item.Value>
      {fieldLabel && (
        <Item.Value>
          <Text variant="caption" color={Token.color.greyTone50}>
            {fieldLabel}
          </Text>
        </Item.Value>
      )}
    </>
  )
}

type FieldConfig = {
  path: string
  label?: string
  insert?: (value: string | number) => React.ReactNode
  omitFrom?: boolean
}
type ChangePreviewProps = {
  data: PayrollTimelineEventInterface
  fields: Array<string | FieldConfig>
}
const ChangePreview = ({ data, fields }: ChangePreviewProps) => {
  const changesFrom = data.content.from_value
  const changesTo = data.content.to_value

  if (!changesFrom && !changesTo) {
    return null
  }
  const isConfig = (field: FieldConfig | string): field is FieldConfig =>
    typeof field === 'object' && 'path' in field
  const canMapFieldValue = (value: unknown): value is string | number =>
    typeof value === 'string' || typeof value === 'number'

  let fieldPath
  let fieldLabel
  let from
  let to

  for (let i = 0; i < fields.length; i++) {
    const fieldSpec = fields[i]
    fieldPath = isConfig(fieldSpec) ? fieldSpec.path : fieldSpec
    fieldLabel = isConfig(fieldSpec) ? fieldSpec.label : undefined

    const omitFrom = isConfig(fieldSpec) ? fieldSpec.omitFrom : undefined
    const insert = isConfig(fieldSpec) ? fieldSpec.insert : undefined

    if (get(changesTo, fieldPath)) {
      from = omitFrom ? undefined : get(changesFrom, fieldPath)
      to = get(changesTo, fieldPath)

      if (!insert) {
        break
      }
      if (canMapFieldValue(from)) {
        from = insert(from)
      }
      if (canMapFieldValue(to)) {
        to = insert(to)
      }
      break
    }
  }

  return fieldPath ? (
    <ChangePreviewValues fieldPath={fieldPath} label={fieldLabel} from={from} to={to} />
  ) : null
}

const getSideValue = (data: PayrollTimelineEventInterface) => {
  if (data.payroll_event === 'hire' || data.payroll_event === 'termination') {
    switch (data.payroll_event) {
      case 'hire': {
        return (
          <ChangePreviewValues
            label="Start date"
            to={formatDate(data.effective_date_time)}
          />
        )
      }
      case 'termination': {
        return (
          <ChangePreviewValues
            label="End date"
            to={formatDate(data.effective_date_time)}
          />
        )
      }
      default:
        return null
    }
  } else if (data.payroll_event === 'data_change') {
    switch (data.content.domain_name) {
      case 'name': {
        return (
          <ChangePreview
            data={data}
            fields={[
              { path: 'name', omitFrom: true },
              { path: 'full_name', omitFrom: true },
              'first_name',
              'middle_name',
              'last_name',
            ]}
          />
        )
      }
      case 'status': {
        return (
          <ChangePreview
            data={data}
            fields={[{ path: 'status.name', label: 'Employee status' }]}
          />
        )
      }
      case 'employee_type': {
        return <ChangePreview data={data} fields={['employee_type']} />
      }
      case 'contract_type': {
        return <ChangePreview data={data} fields={['contract_type']} />
      }
      case 'contract_status': {
        return <ChangePreview data={data} fields={['contract_status']} />
      }
      case 'contract_term': {
        return <ChangePreview data={data} fields={['contract_term']} />
      }
      case 'start_date': {
        return <ChangePreview data={data} fields={['start_date']} />
      }
      case 'end_date': {
        return <ChangePreview data={data} fields={['end_date']} />
      }
      case 'entity': {
        return <ChangePreview data={data} fields={['entity']} />
      }
      case 'location': {
        return <ChangePreview data={data} fields={['location']} />
      }
      case 'salary': {
        return (
          <ChangePreview
            data={data}
            fields={[
              'salary_amount',
              'salary_currency',
              'salary_payment_frequency',
              'salary_time_unit',
            ]}
          />
        )
      }
      case 'sign_on_bonus': {
        return (
          <ChangePreview
            data={data}
            fields={[
              'sign_on_bonus_amount',
              'sign_on_bonus_currency',
              'sign_on_bonus_type',
            ]}
          />
        )
      }
      case 'position': {
        return <ChangePreview data={data} fields={['position']} />
      }
      case 'specialisation': {
        return <ChangePreview data={data} fields={['specialisation']} />
      }
      case 'seniority': {
        return (
          <ChangePreview
            data={data}
            fields={['seniority', 'specialisation_seniority_sublevel']}
          />
        )
      }
      case 'job_title': {
        return <ChangePreview data={data} fields={['title']} />
      }
      case 'weekly_working_hours': {
        return <ChangePreview data={data} fields={['weekly_working_hours']} />
      }
      case 'notice_period_during_probation': {
        return (
          <ChangePreview
            data={data}
            fields={[
              'notice_period_during_probation',
              'notice_period_during_probation_unit',
            ]}
          />
        )
      }
      case 'notice_period_after_probation': {
        return (
          <ChangePreview
            data={data}
            fields={[
              'notice_period_after_probation',
              'notice_period_after_probation_unit',
            ]}
          />
        )
      }
      case 'inactivity': {
        return (
          <ChangePreview
            data={data}
            fields={['inactivity_reason', 'inactivity_start_date', 'inactivity_end_date']}
          />
        )
      }
      case 'approval_status': {
        return <ChangePreview data={data} fields={['approval_status']} />
      }
      case 'personal_email': {
        return (
          <ChangePreview
            data={data}
            fields={[{ path: 'personal_email', omitFrom: true }]}
          />
        )
      }
      case 'legal_sex': {
        return <ChangePreview data={data} fields={['legal_sex']} />
      }
      case 'marital_status': {
        return <ChangePreview data={data} fields={['marital_status']} />
      }
      case 'birth_date': {
        return <ChangePreview data={data} fields={['birth_date']} />
      }
      case 'phone_number': {
        return (
          <ChangePreview
            data={data}
            fields={['phone_number', 'phone_number_short', 'phone_country_code']}
          />
        )
      }
      case 'nationality': {
        return <ChangePreview data={data} fields={['nationality']} />
      }
      case 'nationalities': {
        return <ChangePreview data={data} fields={['nationalities']} />
      }
      case 'languages': {
        return <ChangePreview data={data} fields={['languages']} />
      }
      case 'address': {
        return (
          <ChangePreview
            data={data}
            fields={[
              'country',
              'county',
              'city',
              'address_line_1',
              'address_line_2',
              'address_line_3',
              'post_code',
            ]}
          />
        )
      }
      case 'emergency_contact': {
        return (
          <ChangePreview
            data={data}
            fields={[
              'emergency_contact_full_name',
              'emergency_contact_email',
              'emergency_contact_mobile_phone',
              'emergency_contact_phone_country_code',
              'emergency_contact_phone_number',
              'emergency_contact_relationship',
            ]}
          />
        )
      }
      case 'time_off_request': {
        return (
          <ChangePreview
            data={data}
            fields={[
              'duration',
              'from_date_time',
              { path: 'start_date_time', insert: date => formatDate(date as string) },
              { path: 'end_date_time', insert: date => formatDate(date as string) },
              'total_duration',
              'balance',
              'unit',
            ]}
          />
        )
      }
      default:
        return null
    }
  }
  return null
}

type Props = {
  data: PayrollTimelineEventInterface
}
export const TimelineItem = ({ data }: Props) => {
  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon={categoryToIcon(data)} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{getTitle(data)}</Item.Title>
        <Item.Description>
          {chain(
            data.content.employee.full_name,
            data.effective_date_time
              ? format(new Date(data.effective_date_time), 'dd MMM yyyy')
              : undefined,
          )}
        </Item.Description>
      </Item.Content>
      <Item.Side>{getSideValue(data)}</Item.Side>
    </Item>
  )
}

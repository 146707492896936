import React, { useMemo } from 'react'
import capitalize from 'lodash/capitalize'
import {
  ActionButton,
  ActionButtonSkeleton,
  Flex,
  RadioSelect,
  Tag,
  Text,
  Token,
  useDropdown,
} from '@revolut/ui-kit'

import { PayCycleStatus } from '@src/interfaces/payrollV2'
import { CycleOption } from '../../common'

const getTagAndColors = (statusId?: PayCycleStatus) => {
  if (statusId === 'open') {
    return {
      tag: 'current',
      color: Token.color.green,
      bg: Token.color.green_20,
    }
  }
  if (statusId === 'scheduled') {
    return {
      tag: 'upcoming',
      color: Token.color.greyTone50,
      bg: Token.color.greyTone10,
    }
  }
  if (statusId === 'closed' || statusId === 'pending_closure') {
    return {
      tag: 'history',
      color: Token.color.warning,
      bg: Token.color.orange_20,
    }
  }
  return { title: '', color: '', bg: '' }
}

type TaggedCycleProps = {
  value?: CycleOption
}
const TaggedCycle = ({ value }: TaggedCycleProps) => {
  if (!value) {
    return <Text>Unknown cycle</Text>
  }
  const { tag, ...colors } = getTagAndColors(value.statusId)

  return (
    <Flex alignItems="center" justifyContent="space-between" gap="s-8">
      <Text>{value.name}</Text>
      <Tag {...colors} variant="faded">
        {capitalize(tag)}
      </Tag>
    </Flex>
  )
}

type Props = {
  value: CycleOption | undefined
  onChange: (newValue: CycleOption) => void
  options: CycleOption[]
  isLoading: boolean
}
export const CycleSelector = ({ value, onChange, options, isLoading }: Props) => {
  const dropdown = useDropdown()

  const formattedOptions = useMemo(
    () =>
      options.map(option => {
        return { key: option.id, label: option.name, value: option }
      }),
    [options],
  )

  if (isLoading) {
    return <ActionButtonSkeleton width={200} />
  }
  return (
    <>
      <ActionButton
        useIcon="CalendarDate"
        {...dropdown.getAnchorProps()}
        aria-label="Select current cycle"
        aria-haspopup="listbox"
      >
        <TaggedCycle value={value} />
      </ActionButton>
      <RadioSelect
        {...dropdown.getTargetProps()}
        minWidth={280}
        value={value}
        options={formattedOptions}
        onChange={newValue => {
          if (newValue) {
            onChange(newValue)
          }
        }}
        labelList="Available cycles"
      >
        {option => <TaggedCycle key={option.key} value={option.value} />}
      </RadioSelect>
    </>
  )
}

import { API } from '@src/constants/api'
import { GetRequestInterface, TableRequestInterface } from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import {
  PayCycleInterface,
  PayCycleReportStatsInterface,
  PayCycleReportTableItemInterface,
  PayGroupListItemInterface,
  PayrollTimelineEventInterface,
} from '@src/interfaces/payrollV2'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { api } from '.'

export const payGroupTableRequests = {
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<PayGroupListItemInterface>>(
      `${API.PAYROLL}/payGroups`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
}

// TODO: RHR-5776 migrate to v2 API when BE is ready
export const useGetPayCycle = (id: string) =>
  useFetch<PayCycleInterface>(`${API.PAYROLL}/cycles/${id}`, 'v1')

export const getPayCycleEmployeeReportsTableRequests = (
  id: string | number | undefined,
): TableRequestInterface<
  PayCycleReportTableItemInterface,
  PayCycleReportStatsInterface
> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.PAYROLL}/payCycles/${id}/employeeReports`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
  getStats: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.PAYROLL}/payCycles/${id}/employeeReports/stats`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const useGetPayCycles = (params: object, enabled: boolean = true) =>
  useFetch<GetRequestInterface<PayCycleInterface>>(
    enabled ? `${API.PAYROLL}/cycles` : null,
    'v1',
    { params },
  )

export const useGetPayGroupTimeline = (
  id: string | number | undefined,
  from: string,
  to: string,
) =>
  useFetch<PayrollTimelineEventInterface[]>(
    id ? `${API.PAYROLL}/payGroups/${id}/timeline` : null,
    'v2',
    {
      params: {
        from_date: from,
        to_date: to,
      },
    },
  )

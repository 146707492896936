import React from 'react'

import { PayCycleEmployeeReportsTable } from './EmployeeReportsTable'
import { CommonTabProps } from '../common'

export const PayCycleTabOverview: React.FC<CommonTabProps> = props => {
  return (
    <>
      {/** TODO: RHR-5780 Add report details sidebar */}
      <PayCycleEmployeeReportsTable {...props} />
    </>
  )
}

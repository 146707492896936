import React, { useEffect, useRef, useState } from 'react'
import format from 'date-fns/format'
import getMonth from 'date-fns/getMonth'
import endOfMonth from 'date-fns/lastDayOfMonth'
import startOfMonth from 'date-fns/startOfMonth'
import {
  Avatar,
  Box,
  IconButton,
  Item,
  ItemSkeleton,
  Text,
  Token,
  useIntersectViewport,
  VStack,
} from '@revolut/ui-kit'

import { useGetPayGroupTimeline } from '@src/api/payrollV2'
import { MONTHS } from '@src/features/Calendar/constants'
import { TimelineItem } from './TimelineItem'

const WithTitle: React.FC<{ title: string }> = ({ title, children }) => {
  return (
    <VStack space="s-8">
      <Box pl="s-12">
        <Text variant="emphasis2" color={Token.color.greyTone50}>
          {title}
        </Text>
      </Box>
      {children}
    </VStack>
  )
}

type MonthProps = {
  payGroupId: number | undefined
  startOfMonthDate: Date
  allowLoading: boolean
  onLoaded: (id: number) => void
}
export const Month = ({
  payGroupId,
  startOfMonthDate,
  allowLoading,
  onLoaded,
}: MonthProps) => {
  const monthIdx = getMonth(startOfMonthDate)
  const monthName = MONTHS[monthIdx]

  const firstDayOfMonth = format(startOfMonth(startOfMonthDate), 'yyyy-MM-dd')
  const lastDayOfMonth = format(endOfMonth(startOfMonthDate), 'yyyy-MM-dd')

  const loadOnViewportRef = useRef(null)
  const [loadOnViewport, setLoadOnViewport] = useState(false)

  const canLoad = allowLoading && loadOnViewport

  const {
    data: timelineData,
    isLoading,
    isRefetching,
    refetch,
    isError,
  } = useGetPayGroupTimeline(
    canLoad ? payGroupId : undefined,
    firstDayOfMonth,
    lastDayOfMonth,
  )

  useEffect(() => {
    if (timelineData) {
      onLoaded(startOfMonthDate.valueOf())
    }
  }, [timelineData])

  useIntersectViewport(loadOnViewport ? undefined : loadOnViewportRef, isIntersecting => {
    if (isIntersecting) {
      setTimeout(() => {
        setLoadOnViewport(true)
      }, 100)
    }
  })

  const title = `${MONTHS[monthIdx]} ${startOfMonthDate.getFullYear()}`

  if (!monthName) {
    return null
  }
  if (!loadOnViewport || isLoading || isRefetching) {
    return (
      <>
        <WithTitle title={title}>
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </WithTitle>
        {!loadOnViewport && <div ref={loadOnViewportRef} />}
      </>
    )
  }
  if (!timelineData?.length) {
    return (
      <WithTitle title={title}>
        <Item>
          <Item.Avatar>
            <Avatar useIcon={isError ? 'ExclamationTriangle' : 'Search'} />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>
              {isError
                ? `Error loading data for ${monthName}`
                : `No changes in ${monthName}`}
            </Item.Title>
          </Item.Content>
          <Item.Side>
            {isError && (
              <IconButton
                useIcon="Retry"
                color={Token.color.blue}
                onClick={() => refetch()}
              />
            )}
          </Item.Side>
        </Item>
      </WithTitle>
    )
  }
  return (
    <WithTitle title={title}>
      {timelineData.map(timelineItem => (
        <TimelineItem key={timelineItem.content.id} data={timelineItem} />
      ))}
    </WithTitle>
  )
}
